
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import useVuelidate from "@vuelidate/core"
import Checkbox from 'primevue/checkbox';
import { email, helpers } from "@vuelidate/validators"

import { mapActions, mapGetters } from "vuex";


export default defineComponent({
  name: "SendOrderDialog",
  components: {
    Dialog,
    Button,
    InputText,
    Checkbox,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showModelsCheckbox: {
      type: Boolean,
      default:false,
    },
    showPricesCheckbox: {
      type: Boolean,
      default:false,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  validations(){
    return {
      emailToSend: {
        email: helpers.withMessage("Email not valid", email)
      }
    }
  },
  updated() {
    this.showDialog = this.visible;
    if (this.showDialog) {
      this.emailToSend = this.getUser && this.getUser.email_address_items && this.getUser.email_address_items.length > 0 ? this.getUser.email_address_items[0].email_address : "";
    }
  },
  data() {
    return {
      emailToSend: "",
      showDialog: false,
      showModelNumber: false,
      showPrices: false,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "session/getUser",
    })
  },
  emits: ["onCloseDialog", "onSendEmail"],
  methods: {
    dialogTitle() {
      return `Send order ${this.orderId} by email`;
    },
    closeSendEmailDialog() {
      this.$emit("onCloseDialog");
      this.emailToSend = "";
    },
    handleSendEmail() {
      // We can send the id here if we want
      let modelNo = 'N'
      let prices = 'N'
        if(this.showModelNumber) {
          modelNo = 'Y'
        }
        if(this.showPrices) {
          prices = 'Y'
        }
      this.$emit("onSendEmail", { emailToSend: this.emailToSend, modelNo: modelNo, prices: prices });
    }
  },
})
