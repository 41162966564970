import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "status-column" }
const _hoisted_2 = { class: "actions-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    paginator: true,
    rows: _ctx.rowsPerPage,
    "onUpdate:rows": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onRowsPerPageChange', $event))),
    paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries",
    first: _ctx.firstRow,
    breakpoint: _ctx.breakpoint,
    responsiveLayout: "stack",
    value: _ctx.items,
    rowsPerPageOptions: [5, 10, 20, 50],
    selection: _ctx.selectedRow,
    "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRow) = $event)),
    dataKey: _ctx.dataKey,
    selectionMode: _ctx.selectionMode,
    onRowClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rowClick($event))),
    "onUpdate:first": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.firstRow) = $event)),
    onPage: _ctx.onPageClicked,
    onSort: _ctx.onSort,
    sortField: _ctx.sortField,
    sortOrder: _ctx.sortOrder,
    expandedRows: _ctx.expandedRows,
    "onUpdate:expandedRows": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.expandedRows) = $event)),
    filterDisplay: "menu",
    filters: _ctx.filters
  }, {
    empty: _withCtx(() => [
      _createTextVNode(" No records found ")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        expander: _ctx.expansion,
        headerStyle: "width: 3rem"
      }, null, 8, ["expander"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
        return (_openBlock(), _createBlock(_component_Column, {
          field: col.field,
          header: col.header,
          key: col.field,
          sortable: col.sort,
          showFilterMatchModes: true,
          showFilterOperator: false,
          bodyStyle: _ctx.getColumnBodyStyle(col)
        }, _createSlots({
          filterclear: _withCtx(() => [
            _createVNode(_component_Button, {
              type: "button",
              label: "Clear",
              onClick: ($event: any) => (_ctx.clearFilter(col.field))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(() => [
            _createVNode(_component_Button, {
              type: "button",
              label: "Search",
              onClick: _ctx.onFetchData
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, [
          (col.options)
            ? {
                name: "body",
                fn: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(col.options.find((code) => code[col.optionValue] === data[col.field])[col.optionLabel]), 1)
                ]),
                key: "0"
              }
            : (col.format === 'currency')
              ? {
                  name: "body",
                  fn: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(parseFloat(data.order_amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })), 1)
                  ]),
                  key: "1"
                }
              : (col.field === 'quote_amount')
                ? {
                    name: "body",
                    fn: _withCtx(({ data }) => [
                      (data.quote_amount)
                        ? (_openBlock(), _createElementBlock("span", { key: 0 }, _toDisplayString(_ctx.formatCurrency(data.quote_amount)), 1))
                        : (!data.qoute_amount && data.li_items && data.li_items.length)
                          ? (_openBlock(), _createElementBlock("span", { key: 1 }, " View For More Details"))
                          : (!data.qoute_amount && !data.li_items?.length)
                            ? (_openBlock(), _createElementBlock("span", { key: 2 }, " No Line Items "))
                            : _createCommentVNode("", true)
                    ]),
                    key: "2"
                  }
                : undefined,
          (col.filter)
            ? {
                name: "filter",
                fn: _withCtx(() => [
                  (col.filter && col.filterType === 'text')
                    ? (_openBlock(), _createBlock(_component_InputText, {
                        key: 0,
                        modelValue: _ctx.localFilters[col.field].newVal,
                        "onUpdate:modelValue": ($event: any) => ((_ctx.localFilters[col.field].newVal) = $event),
                        placeholder: "Search"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    : (col.filter && col.filterType === 'dropDown' && col.optionLabel )
                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                          key: 1,
                          modelValue: _ctx.localFilters[col.field].newVal,
                          "onUpdate:modelValue": ($event: any) => ((_ctx.localFilters[col.field].newVal) = $event),
                          options: col.options,
                          optionLabel: col.optionLabel,
                          optionValue: col.optionValue,
                          filterMatchMode: "startsWith",
                          style: { width: '200px' }
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "optionLabel", "optionValue"]))
                      : (col.filter && col.filterType === 'calendar')
                        ? (_openBlock(), _createBlock(_component_Calendar, {
                            key: 2,
                            modelValue: _ctx.localFilters[col.field].newVal,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.localFilters[col.field].newVal) = $event),
                            dateFormat: "mm-dd-yy",
                            placeholder: "Select Date"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                        : (col.filter && col.filterType === 'calendarRange')
                          ? (_openBlock(), _createBlock(_component_Calendar, {
                              key: 3,
                              modelValue: _ctx.localFilters[col.field].newVal,
                              "onUpdate:modelValue": ($event: any) => ((_ctx.localFilters[col.field].newVal) = $event),
                              dateFormat: "mm-dd-yy",
                              placeholder: "Select Date",
                              selectionMode: "range"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                          : _createCommentVNode("", true)
                ]),
                key: "3"
              }
            : undefined
        ]), 1032, ["field", "header", "sortable", "bodyStyle"]))
      }), 128)),
      (_ctx.actions.length > 0)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            header: "Actions"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${action.name}-${data.so_id || data.id}`,
                    class: "inline"
                  }, [
                    _createVNode(_component_Button, {
                      type: "button",
                      icon: action.icon ? action.icon : action.getIconClass !== undefined ? action.getIconClass(data) : 'pi pi-check',
                      onClick: ($event: any) => (action.action(data)),
                      title: `${action.tooltipMsg} - ${data.so_id || data.id}`
                    }, null, 8, ["icon", "onClick", "title"])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["rows", "first", "breakpoint", "value", "selection", "dataKey", "selectionMode", "onPage", "onSort", "sortField", "sortOrder", "expandedRows", "filters"]))
}