
import { defineComponent } from "vue";
import Sales from "@/components/Sales.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {
    Sales,
    Footer,
  },
});
