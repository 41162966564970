
import { defineComponent } from "vue";
import DataGrid from "@/components/DataGrid.vue";
import Card from "primevue/card";
import { mapActions, mapGetters, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NotificationCard from "@/components/Notification-Card.vue";
import SideOrder from './Orders/SideOrder.vue';
import Steps from "primevue/steps"
import Dialog from "primevue/dialog"
import Button from "primevue/button";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import SendOrderDialog from "@/components/UI/SendOrderDialog.vue";
import { FilterMatchMode } from "primevue/api";

import store from "@/store";

export default defineComponent({
  name: "Sales",
  components: {
    DataGrid,
    Card,
    LoadingSpinner,
    NotificationCard,
    SideOrder,
    Steps,
    CollapsibleSection,
    Dialog,
    Button,
    SendOrderDialog,
  },
  data() {
    return {
      printExportItems: [],
      totalRecords: 0,
      maxExportRows: 1000,
      rangeStart: 1,
      rangeEnd: 10,
      showSendEmailDialog: false,
      orderIdToSend: '',
      addingReorderItems: [] as Array<any>,
      showReorderConfirmation: false,
      sectionsStatus: {
        pastOrders: false,
        pos: true,
      },
      items: [] as Array<SalesOrder>,
      selectedCustomer: null,
      selectedSale: null,
      filters: {
        so_id: {
          value: undefined as any,
          matchMode: FilterMatchMode.EQUALS,
          newVal: undefined as any,
        },
        status: {
          value: undefined as any,
          matchMode: FilterMatchMode.IN,
          newVal: undefined as any,
        },
        date: {
          value: undefined as any,
          matchMode: FilterMatchMode.IN,
          newVal: undefined as any,
        },
      },
      readOnly: true,
      readOnlyPart: false,
      readOnlyField: false,
      readOnlyButton: false,
      first: 0,
      page: 1,
      rows: 5,
      emailLoading: false,
      isLoadingSales: false,
      orderSubmitted: false,
      columns: [
        { field: "so_id", header: "ID", sort: true, filter: 'id', filterType: 'text' },
        { field: "date", header: "Order Date", sort: false },
        { field: "status", header: "Status", sort: true, filter: 'status', filterType: 'dropDown', optionLabel: 'status', optionValue: 'initial', 
          options: [
            { status: "New", initial: "N" },
            { status: "Back Order", initial: "B" },
            { status: "Closed", initial: "C" },
          ]
        },
        { field: "po_number", header: "PO", sort: true, filter: null },
        { field: "order_amount", header: "Order Amount", sort: true, format: "currency"}
      ],
      tableActions: [
        {
          name: "downloadPDF",
          tooltipMsg: "Download PDF",
          getIconClass: (data: any) => this.orderPDFIcon(data.so_id),
          action: (data: any): void => this.handleGetOrderPDF(data.so_id)
        },
        {
          name: "reorder",
          icon: "pi pi-replay",
          tooltipMsg: "Reorder Items",
          action: (data: any) => this.handleShowReorderConfirmation(data.lis_items ? data.lis_items : [])
        },
        {
          name: "email",
          icon: "pi pi-envelope",
          tooltipMsg: "Email Order",
          action: (data: any) => this.handleEmailOrderPDF(data.so_id)
        }
      ],
      allExportColumns: [
        { field: "so_id", header: "ID", input: true },
        { field: "date", header: "Order Date", input: true },
        { field: "status", header: "Status", input: true },
        { field: "po_number", header: "PO", input: true },
        { field: "order_amount", header: "Order Amount", input: true },
      ],
      dynamicColumns: [
        { field: "so_id", header: "ID", input: true },
        { field: "date", header: "Order Date", input: true },
        { field: "status", header: "Status", input: true },
        { field: "po_number", header: "PO", input: true },
        { field: "order_amount", header: "Order Amount", input: true },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      customer: null as unknown,
      sort: {
        sortBy: "",
        sortOrder: 0,
      },
    };
  },
  computed: {
    ...mapState(["orders"]),
    ...mapGetters({
      rangeEnd: "sales/getRangeEnd",
      getCust: "customer/getCust",
      getFirstCust: "session/getFirstCust",
      getPDFS: "pos/getPDFS",
      getLoadingPDFs: "pos/getLoadingPDFs",
      getFilters: "control/getFilterItems",
      getOrder: "pos/getCurrentOrder",
    }),
    customerId(): string {
      if (this.getCust !== null) return this.getCust.cust_id;
      else {
        return this.getFirstCust.cust_id;
      }
    },
    computedSteps() {
      const currentOrder = store.getters["pos/getCurrentOrder"];
      const steps = [
        {
          label: 'Create Order',
          to: '/sales/parts',
          visible: true,
        },
        {
          label: 'Order Information',
          to: '/sales/shipping',
          visible: true,
          disabled: (!Array.isArray(currentOrder) || currentOrder.length === 0),
        },
        // {            
        //   label: 'Payment',
        //   to: '/sales/payment',
        //   visible: true,
        //   disabled: (!Array.isArray(currentOrder) || currentOrder.length === 0),
        // },
        {
          label: 'Edit Estimate',
          to: '/sales/estimate',
          visible: false,
          disabled: true,
        },
        {
          label: 'Confirmation',
          to: '/sales/confirmation',
          visible: true,
          disabled: true,
        }
      ]

      const visibleSteps = steps.filter(step => step.visible === true);
      return visibleSteps
    },
  },
  created() {
      this.isLoadingSales = true;
      this.fetchOrdersRecords({
        custId: this.customerId,
        correls: "order_amount",
      }).then((resp: any) => {
        this.isLoadingSales = false;
        this.items = resp.so_items;
        this.printExportItems = resp.so_items.map((item: any) => {
            return {
              so_id: item.so_id,
              date: item.date,
              status: this.formatStatus(item.status),
              po_number: item.po_number,
              order_amount: item.order_amount
            }
          });
        this.totalRecords = resp.so_items.length;
      });

    this.customer = this.getCust;
  },
  methods: {
    ...mapActions({
      setFirstRow: "sales/setFirstRow",
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      getOrderPDF: "pos/getOrderPDF",
      fetchControls: "control/fetchControls",
      updatePartQty: "pos/updatePartQty",
      addPartToOrder: "pos/addPartToOrder",
      clearOrder: "pos/clearOrder",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
      addNotification: "notification/add",
    }),
    async printTable() {
      //block a print with no criteria applied
      if(this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchFilteredOrders(false);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open(
          "/printable-view?print=1&showSelection=1",
          "_blank"
        );
      }
    },
    handleOrderCreated(message: string) {
      this.fetchFilteredOrders(false);
    },
    updateFilters(event: any) {
      this.filters = event
      this.fetchFilteredOrders(false)
    },
    handleOrderSubmitted(value: string) {
      this.orderSubmitted = value as unknown as boolean
    },
    handleShowReorderConfirmation(items: any[]) {
      if(!this.getOrder || this.getOrder.length === 0) {
        this.handleReorderItems(items)
      } else {
        this.showReorderConfirmation = true;
        this.addingReorderItems = items;
      }

    },
    handleClearAndReorderItems(items: any[]) {
      this.clearOrder()
      this.handleReorderItems(items)
    },
    handleReorderItems(items: any[]) {
      items.forEach((item: any) => {
        const data = {
          category: item.cg_loc,
          code_items: [],
          desc: item.wrap_desc ? item.wrap_desc.replaceAll("^", " ") : `Part No. ${item.li_parts}`,
          part_number: item.li_parts,
          price: item.li_prices,
          quantity: item.li_order_qtys ? parseFloat(item.li_order_qtys).toFixed(2) : 0,
          std_price_items: [],
          web_category: []
        }
        this.addItem(data)
      })
      
      this.showReorderConfirmation = false;
      this.addingReorderItems = []
    },
    addItem(part: any) {
      const index = this.getOrder.findIndex((item: any) => {
        return (item.part_number === part.part_number) && (item.price === part.price)
      });
      if(index >= 0) {
        this.getOrder[index].quantity = (parseFloat(this.getOrder[index].quantity) + parseFloat(part.quantity)).toFixed(2).toString(); 
        this.updatePartQty({partIndex: index, part: this.getOrder[index]})
      } else {
        if(part.quantity > 0) {
          part['amount'] = part.price;
          this.addPartToOrder(JSON.parse(JSON.stringify(part)))
        }
      }
    },
    showRow(event: any) {
      this.salesOrder = event.data;
      this.openSales(true);
    },
    getLength() {
      return this.items.length;
    },
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
      this.readOnlyPart = true;
      this.readOnlyField = true;
      this.readOnlyButton = true;
    },
    openNewsSales() {
      this.readOnly = true;
      this.readOnlyPart = false;
      this.readOnlyField = false;
      (this.readOnlyButton = false), (this.salesOrder = null);
      this.showSalesDialog = true;
    },
    sortOrders(event: any) {
      this.sort.sortOrder = event.sortOrder
      this.sort.sortBy = event.sortField
      this.fetchFilteredOrders(false)
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.items.length == this.rangeEnd - 100
      ) {
        this.fetchFilteredOrders(true);
      }
    },
    formatStatus(status: string) {
      switch (status) {
        case "N":
          return "New";
        case "B":
          return "Back Order";
        case "C":
          return "Closed";
        default:
          return "NA";
      }
    },
    fetchFilteredOrders(addOrder: boolean) {
      this.isLoadingSales = true;
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      let sortBy
      switch(this.sort.sortBy){
        case "so_id":
          sortBy = "so.id"
          break;
        case "order_amount":
          sortBy = "order.amount"
          break;
        case "po_number":
          sortBy = "po.number"
          break;
        default:
          sortBy = this.sort.sortBy
          break;
      }
      this.fetchOrdersRecords({
        custId: this.customerId,
        id: this.filters.so_id.value,
        status: this.filters.status.value,
        addOrder: addOrder,
        correls: "order_amount",
        sortBy: sortBy,
        sortOrder: this.sort.sortOrder === -1 ? "DEC" : "",
      })
        .then((resp: any) => {
          this.isLoadingSales = false;
          if(addOrder) {
            this.items.push(...resp.so_items)
          } else {
            this.items = resp.so_items;
          }
          this.printExportItems = resp.so_items.map((item: any) => {
            return {
              so_id: item.so_id,
              date: item.date,
              status: this.formatStatus(item.status),
              po_number: item.po_number,
              order_amount: item.order_amount
            }
          });
          this.totalRecords = resp.so_items.length;
          
        })
        .catch(() => {
          this.isLoadingSales = false;
        });
    },
    toggleSectionIcon(tab: "pastOrders" | "pos") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    orderPDFIcon(id: string) {
      let downloaded: Array<any> = this.getPDFS
      let downloading: boolean = this.getLoadingPDFs.find((i: string) => i === id) !== undefined
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    async handleGetOrderPDF(orderId: string) {
      await this.getOrderPDF({recordId: orderId})
    },
    handleEmailOrderPDF(orderId: string) {
      this.showSendEmailDialog = true;
      this.orderIdToSend = orderId;
    },
    closeSendEmailDialog() {
      this.showSendEmailDialog = false;
      this.orderIdToSend = '';
    },
    handleSendEmail(data: any) {
      this.emailLoading = true;
      this.getOrderPDF({recordId: this.orderIdToSend, email: data.emailToSend})
      .finally(() => {
        this.emailLoading = false
        this.closeSendEmailDialog();
      })
    },
  },
});
